/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Img from 'gatsby-image'

// Libraries
import _ from 'lodash'

// CSS
import './Points.scss'
import { useStaticQuery, graphql } from 'gatsby'

const Points = () => {
  const {
    page: {
      acf: {
        points,
      }
    }
  } = useStaticQuery(graphql`
    {
      page: wordpressPage(wordpress_id: { eq: 7 }) {
        acf {
          points {
            title
            description
            icon {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 64) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)


  return (
    <div className="points row">
      {_.map(points, ({ title, description, icon }, index) => (
        <div className="col-lg text-center px-lg-5 mt-5 mt-lg-0" key={index}>
          <Img
            className="points-image d-inline-block"
            fluid={icon.localFile.childImageSharp.fluid}
          />
          <h3 className="color-text-secondary text-uppercase font-size-m font-weight-l mt-4">
            {title}
          </h3>
          <p className="font-family-secondary font-size-sm mt-3 mx-lg-3">
            {description}
          </p>
        </div>
      ))}
    </div>
  )
}

export default Points
